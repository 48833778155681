import { ThemeTypes } from "../../components/theming-options/theming-options.component";
import { Badge } from "../badge/Badge";
import { UserDto } from "./UserDto";

export class User {
  id: number;
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  badge?: Badge;
  profilePicture?: string;
  theme?: ThemeTypes;

  constructor(data: UserDto) {
    this.id = data.id;
    this.email = data.email;
    this.firstName = data.first_name;
    this.lastName = data.last_name;
    this.userName = data.username;
    this.fullName = data.first_name || data.last_name ?  `${data.first_name} ${data.last_name}` : data.email;
    this.profilePicture = data.profile_picture;
    this.theme = data.theme;

    if (data.badge) {
      this.badge = new Badge(data.badge);
    }
  } 
}
