import { Rewards } from "../rewards/Rewards";
import { BadgeDto } from "./BadgeDto";

export class Badge {
  createdAt: Date;
  updatedAt: Date;
  id: number;
  isBadged: boolean;
  isClaimed: boolean;
  user: number;
  reward: Rewards;

  constructor(badge: BadgeDto) {
    this.createdAt = badge.created_at;
    this.updatedAt = badge.updated_at;
    this.id = badge.id;
    this.isBadged = badge.is_badged;
    this.isClaimed = badge.is_claimed;
    this.reward = new Rewards(badge.reward);
    this.user = badge.user;
  }
}
