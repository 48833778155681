import { Base } from "../Base";
import { RewardsDto } from "./RewardsDto";

export class Rewards extends Base {
  rarity: string; // can be enum once already implemented in the future
  name: string;
  description: string;
  image: string;
  badge: string;
  cost: number;
  hiddenUntilClaimed: boolean;

  constructor(rewards: RewardsDto) {
    super(rewards);

    this.rarity = rewards.rarity;
    this.name = rewards.name;
    this.description = rewards.description;
    this.image = rewards.image;
    this.badge = rewards.badge;
    this.cost = rewards.cost;
    this.hiddenUntilClaimed = rewards.hidden_until_claimed;
  }
}
